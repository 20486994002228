import classNames from 'classnames'
import { useEffect, useState } from 'react'
import './App.scss'
import { getSheetv4 } from './utils'
import WpContent from './WpContent'
import BgVideo from './bg.mp4'
import TweetButton from './TweetButton'
import RepFinder from './RepFinder'
import { set, take } from 'ramda'
import AKForm from './ActionNetworkForm'

const useViewportSize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
function getQueryParamValue(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

const RESPONSES_TO_DISPLAY = ['uncommitted', 'no response']
var usStates = [
    { name: 'ALABAMA', abbreviation: 'AL'},
    { name: 'ALASKA', abbreviation: 'AK'},
    { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
    { name: 'ARIZONA', abbreviation: 'AZ'},
    { name: 'ARKANSAS', abbreviation: 'AR'},
    { name: 'CALIFORNIA', abbreviation: 'CA'},
    { name: 'COLORADO', abbreviation: 'CO'},
    { name: 'CONNECTICUT', abbreviation: 'CT'},
    { name: 'DELAWARE', abbreviation: 'DE'},
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
    { name: 'FLORIDA', abbreviation: 'FL'},
    { name: 'GEORGIA', abbreviation: 'GA'},
    { name: 'GUAM', abbreviation: 'GU'},
    { name: 'HAWAII', abbreviation: 'HI'},
    { name: 'IDAHO', abbreviation: 'ID'},
    { name: 'ILLINOIS', abbreviation: 'IL'},
    { name: 'INDIANA', abbreviation: 'IN'},
    { name: 'IOWA', abbreviation: 'IA'},
    { name: 'KANSAS', abbreviation: 'KS'},
    { name: 'KENTUCKY', abbreviation: 'KY'},
    { name: 'LOUISIANA', abbreviation: 'LA'},
    { name: 'MAINE', abbreviation: 'ME'},
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
    { name: 'MARYLAND', abbreviation: 'MD'},
    { name: 'MASSACHUSETTS', abbreviation: 'MA'},
    { name: 'MICHIGAN', abbreviation: 'MI'},
    { name: 'MINNESOTA', abbreviation: 'MN'},
    { name: 'MISSISSIPPI', abbreviation: 'MS'},
    { name: 'MISSOURI', abbreviation: 'MO'},
    { name: 'MONTANA', abbreviation: 'MT'},
    { name: 'NEBRASKA', abbreviation: 'NE'},
    { name: 'NEVADA', abbreviation: 'NV'},
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
    { name: 'NEW JERSEY', abbreviation: 'NJ'},
    { name: 'NEW MEXICO', abbreviation: 'NM'},
    { name: 'NEW YORK', abbreviation: 'NY'},
    { name: 'NORTH CAROLINA', abbreviation: 'NC'},
    { name: 'NORTH DAKOTA', abbreviation: 'ND'},
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
    { name: 'OHIO', abbreviation: 'OH'},
    { name: 'OKLAHOMA', abbreviation: 'OK'},
    { name: 'OREGON', abbreviation: 'OR'},
    { name: 'PALAU', abbreviation: 'PW'},
    { name: 'PENNSYLVANIA', abbreviation: 'PA'},
    { name: 'PUERTO RICO', abbreviation: 'PR'},
    { name: 'RHODE ISLAND', abbreviation: 'RI'},
    { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
    { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
    { name: 'TENNESSEE', abbreviation: 'TN'},
    { name: 'TEXAS', abbreviation: 'TX'},
    { name: 'UTAH', abbreviation: 'UT'},
    { name: 'VERMONT', abbreviation: 'VT'},
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
    { name: 'VIRGINIA', abbreviation: 'VA'},
    { name: 'WASHINGTON', abbreviation: 'WA'},
    { name: 'WEST VIRGINIA', abbreviation: 'WV'},
    { name: 'WISCONSIN', abbreviation: 'WI'},
    { name: 'WYOMING', abbreviation: 'WY' }
]
const fullState = abbr => {
  const st8 = usStates.find(state => state.abbreviation === abbr)
  return st8 ? st8.name : ''
}

function App () {
  const isMobile = useViewportSize();
  const [legs, setLegs] = useState([])
  const [title, setTitle] = useState('')
  const [continueReading, setContinueReading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [zipReps, setZipReps] = useState(false)
  const [googleRes, setGoogleRes] = useState(false)
  const [logos, setLogos] = useState([])
  const [copy, setCopy] = useState('')
  const [formCopy, setFormCopy] = useState('')
  const [zip, setZip] = useState('')
  const [sent, setSent] = useState(getQueryParamValue('show') === 'call')
  const [settings, setSettings] = useState({
    showSponser: false,
    showPartial: false,
    showYes: false,
    filter: '',
    dems: false,
    reps: false,
    house: false,
    senate: false
  })
  useEffect(() => {
    getSheetv4('A1:Z600')
      .then(res => {
        setLegs(res || [])
      })

    // document.body.addEventListener('submit', function(event) {
    //   if (event.target.id === 'letter-form') {
    //     setSent(true)
    //   }
    // });
  }, [setLegs])
  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        if (window.CallPowerOptions) return
        window.CallPowerOptions = {
          form: "#call_form",
          phoneField: "#phone_id",
          locationField: "#location_id",
          scriptDisplay: "overlay",
        }
        const script = document.createElement('script')
        script.src = 'https://demandprogress.callpower.org/api/campaign/256/embed.js'
        document.body.appendChild(script)

      }, 1000)
    }
  }, [sent])
  useEffect(() => {
    setTimeout(() => {
      const style = document.createElement('link')
      style.href = 'https://actionnetwork.org/css/style-embed-v3.css'
      style.rel = 'stylesheet'
      document.head.appendChild(style)

      const params = new URLSearchParams(window.location.search)
      const script2 = document.createElement('script')
      script2.src = `https://actionnetwork.org/widgets/v5/letter/ceasefire-now-8?format=js&source=widget&referrer=${encodeURIComponent(params.get('referrer') || '')}`
      document.body.appendChild(script2)

    }, 1000)
  }, [isMobile])
  const submit = () => {
    setSearching(true)
    setGoogleRes(false)
    fetch(`https://www.googleapis.com/civicinfo/v2/representatives?key=AIzaSyDw0EHBqzbHLNDCHVVhHEKaE8jS3Hir3lU&address=${settings.filter}`)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          return
        }
        console.log(res)
        setSettings({
          ...settings,
          filter: res.normalizedInput.line1 + ', ' + res.normalizedInput.city + ', ' + res.normalizedInput.state + ' ' + res.normalizedInput.zip
        })
        setGoogleRes(res)
        setSearching(false)
      })
  }
  const submit_old = (e) => {
    let z = zip
    if (typeof e === 'string') {
      z = e
    } else {
      e && e.preventDefault && e.preventDefault()
    }
    // if (z.length !== 5) return
    setSearching(true)
    setSettings({
      ...settings,
      filter: z
    })
    // AIzaSyDw0EHBqzbHLNDCHVVhHEKaE8jS3Hir3lU
    // fetch(`https://zip-2-rep-tweet.now.sh/?raw=true&zipcode=${z}`)
    fetch(`https://www.googleapis.com/civicinfo/v2/representatives?key=AIzaSyDw0EHBqzbHLNDCHVVhHEKaE8jS3Hir3lU&address=${z}`)
      .then(res => res.json())
      .then(res => {
        console.log(res)
        return
        setZipReps(res.map(rep => {
          return {
            first: rep.name.first,
            last: rep.name.last,
          }
        }))
        // insert script - https://demandprogress.callpower.org/api/campaign/256/embed.js
        setSearching(false)
      })
  }
  useEffect(() => {
    if (/[0-9]{5}/.test(settings.filter)) {
      setZip(settings.filter)
      submit(settings.filter)
    } else {
      setZipReps(false)
    }
  }, [settings.filter, setSearching, setZipReps])
  const toLower = str => str ? str.toLowerCase() : ''
  const legIsSponser = (leg) => {
    return toLower(leg['agree to co-sponsor the yemen wpr']) === 'yes'
  }
  const legIsFull = (leg) => {
    const res = toLower(leg['called for ceasefire?']) 
    return res === 'yes'
  }
  const legIsPartial = (leg) => {
    const res = toLower(leg['called for ceasefire?']) 
    return res === 'partial'
  }
  const legIsYes = (leg) => {
    return legIsFull(leg) || legIsPartial(leg) 
  } 
  const getParty = (leg) => {
    return toLower(leg['party code'])
  }
  const legsToShow = legs.filter(leg => {
    let ret = true
    if (settings.showSponser && settings.showPartial) {
      if (!legIsFull(leg) && !legIsPartial(leg)) {
        ret = false
      }
    } else if (settings.showSponser) {
      if (!legIsFull(leg)) {
        ret = false
      }
    } else if (settings.showPartial) {
      if (!legIsPartial(leg)) {
        ret = false
      }
    }
    if (settings.filter &&
      !(
        toLower(leg['last name']).includes(settings.filter.toLowerCase()) || 
        toLower(leg['first name']).includes(settings.filter.toLowerCase()) ||
        toLower(leg['state']).includes(settings.filter.toLowerCase()) ||
        toLower(fullState(leg['state'])).includes(settings.filter.toLowerCase())
      )) {
      ret = false
    }
    if (zipReps) {
      ret = false
    }
    // set to true if reps exists
    if (zipReps && zipReps.find(rep => rep.first === leg['first name'] && rep.last === leg['last name'])) {
      ret = true
    }
    if (googleRes) {
      const lastNames = googleRes.officials.map(official => official.name.split(' ').slice(-1)[0])
      ret = !!lastNames.find(lastName => {
        const regex = new RegExp(lastName, 'i')
        return regex.test(leg['last name'])
      }) && leg['state'] === googleRes.normalizedInput.state
    }
    if (settings.house && settings.senate) {

    } else if (settings.house && leg['chamber'].toLowerCase() !== 'house') {
      ret = false
    } else if (settings.senate && leg['chamber'].toLowerCase() !== 'senate') {
      ret = false
    }
    if (!settings.dems && !settings.reps) return ret
    
    if (settings.dems && settings.reps) {
      if (getParty(leg) === 'i') {
        ret = false
      }
    } else if (settings.dems) {
      if (getParty(leg) !== 'd') {
        ret = false
      }
    } else if (settings.reps) {
      if (getParty(leg) !== 'r') {
        ret = false
      }
    }
    return ret
  })
  const dontShowSummary = false
  // const dontShowSummary = settings.filter || zipReps || Object.keys(settings).filter(i => ['house', 'senate', 'dems', 'reps'].includes(i)).filter(i => settings[i]).length > 1
  let t = <h1>{title}</h1>
  if (/:/.test(title)) {
    t = <>
      <h1>{title.split(':')[0]}:</h1>
      <h2>{title.split(':')[1]}</h2>
    </> 
  }
  let cta = (
    <><span>TAKE</span> <span>ACTION!</span></>
  )
  let takeAction = (
    <div className='zip-wrap  pt-3'>
      {/* <p className='text-xl mb-4'>Enter your zip code below to contact your members of Congress.</p>
      <form onSubmit={submit} className='flex items-center' >
        <input type='text' className='text-input py-1 px-3 text-xl text-black mr-3 block w-full rounded-lg bg-white-off border-transparent    focus:border-gray-500 focus:bg-white focus:ring-0' placeholder='zip code...' value={zip} onChange={e => setZip(e.target.value)} />
        <input type='submit' className='btn-round' value='→' />
      </form> */}
    </div>
  )
  if (zipReps) {
    takeAction = (
      sent ? (
        <h2 className='my-8'>
          Thanks. Now, can you call your reps? 
        </h2>
      ) : (
        <>
          <h2 className='mt-4'><span>CALL</span> <span>YOUR</span> <span>REPS!</span></h2>
        </>
      )
    )
  }
  const theFilters = (
    <div className='filters'>
      {searching ? (
        <div>Searching...</div>
      ) : (
        <input
          type='text'
          value={settings.filter}
          placeholder='search by name, state or address...'
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              submit()
            }
          }}
          onChange={(e) => {
            setGoogleRes(false)
            setSettings({
              ...settings,
              filter: e.target.value
            })
          }}
        />
      )}
      <div className='box-wrap'>
        <h3>FILTER BY:</h3>
        <div>
          <label>
            <input type='checkbox' checked={settings.dems} onChange={(e) => setSettings({ ...settings, dems: e.target.checked })} />
            Democrats
          </label>
          <label>
            <input type='checkbox' checked={settings.reps} onChange={(e) => setSettings({ ...settings, reps: e.target.checked })} />
            Republicans
          </label>
          <label>
            <input type='checkbox' checked={settings.house} onChange={(e) => setSettings({ ...settings, house: e.target.checked })} />
            HOUSE
          </label>
          <label>
            <input type='checkbox' checked={settings.senate} onChange={(e) => setSettings({ ...settings, senate: e.target.checked })} />
            SENATE
          </label>
          <label className='full-support'>
            <input type='checkbox' checked={settings.showSponser} onChange={(e) => {
              const obj = { ...settings, showSponser: e.target.checked } 
              // if (e.target.checked) {
              //   obj.showPartial = false
              // }
              setSettings(obj)
            }} />
          Full Support 
          </label>
          <label className='support'>
            <input type='checkbox' checked={settings.showPartial} onChange={(e) => {
              const obj = { ...settings, showPartial: e.target.checked }
              // if (e.target.checked) {
              //   obj.showSponser = false
              // }
              setSettings(obj)
            }} />
          Partial Support/Needs Improvement
          </label>
        </div>
      </div>
      <p className='text-xs mb-6 italic leading-normal info mt-2'><sup>*</sup>For more info on what qualifies as ceasefire support, click <a href="https://docs.google.com/document/u/2/d/e/2PACX-1vTR2JpkJK0ea1XpQ7xD8wL7Dq40z9rgvMAAEahfaaFWpiUNSmtQLG2ZyL395v3iYLEpcAWUtjFgwY0M/pub" target="_blank">here</a>. Tips? Questions? Please report back any info you receive from your member of Congress. Email <a href="mailto:stopwar@demandprogress.org">stopwar@demandprogress.org</a></p>
    </div>
  )
  let yeses = legsToShow.filter(leg => legIsYes(leg))
  // console.log('legs', legsToShow)
  let mocLabel = 'Members of Congress'
  let howMany = legsToShow.length
  if (settings.house && !settings.senate) {
    mocLabel = 'House Members'
  } else if (settings.senate && !settings.house) {
    mocLabel = 'Senators'
  }
  if (googleRes && settings.filter) {
    mocLabel = 'Local Members of Congress'
  }
  let party = ''
  if (settings.dems && settings.reps) {
    party = 'Democratic and Republican '
  } else if (settings.dems) {
    party = 'Democratic '
  } else if (settings.reps) {
    party = 'Republican '
  }
  let support = 'support some form of' 
  let what = 'a ceasefire  or cessation of hostilities'
  if (settings.showSponser && !settings.showPartial) {
    support = 'fully support'
  } else if (!settings.showSponser && settings.showPartial) {
    support = 'partially support'
  }
  if (settings.showPartial) {
    what = 'a ceasefire, cessation of hostilities, or temporary pause'
  }
  // nothing checked
  if (!settings.dems &&
    !settings.reps &&
    !settings.house &&
    !settings.senate &&
    !settings.showSponser &&
    !settings.showPartial &&
    !settings.filter
  ) {
    yeses = legsToShow.filter(leg => legIsFull(leg))
    support = 'fully support'
  }
  let summary = (
    <>
      <span className='big'><b>{yeses.length}</b>/{howMany}</span> <em>{party}{mocLabel} {support} {what}<sup>*</sup></em>
      {!settings.dems && !settings.reps && (
        <div>
          <b>{yeses.filter(l => getParty(l) === 'd').length}</b> Democrats &nbsp;
          <b>{yeses.filter(l => getParty(l) === 'r').length}</b> Republicans &nbsp;
          <b>{yeses.filter(l => getParty(l) === 'i').length}</b> Independents
        </div>
      )}
    </>
  )
  if (dontShowSummary) {
    summary = false
  }

  let partners = null
  if (logos.length > 0) {
    partners = (
      <div className='partners'>
        <h3>Partners</h3>
        <p>Partner statements can be found <a className='text-main underline' href="https://demandprogress.org/broad-coalition-launches-ceasefireaction-com-to-build-pressure-on-congress-ahead-of-israels-planned-attack-on-rafah/" target="_blank">here.</a></p>
        <div className='logos'>
          {logos.map((logo, i) => {
            return (
              <div className='img' style={{ backgroundImage: `url(${logo.logo})` }} key={`l-${i}`}></div>
            )
          })}
        </div>
        <div className='text-sm mt-4 flex justify-between'>
          <span>© Demand Progress</span> 
          <a href="https://demandprogress.org/privacy-policy/" className='text-main'>Privacy Policy</a>
        </div>
      </div>
    )
  }
  const totalTakeAction = sent ? (
    <>
      <h2>CALL YOUR CONGRESS-<br/>MEMBERS!</h2>
      <p className='p-4 px-10'>Call your members of Congress and urge them to support an immediate, permanent ceasefire. It's urgent that we act now!</p>
      <form id="call_form" className='p-6'>
        <input type='text' className='text-input py-1 px-3 text-xl text-black mb-3 block w-full rounded-lg bg-white-off border-transparent    focus:border-gray-500 focus:bg-white focus:ring-0' id='phone_id' placeholder='phone number...' />
        <input type='text' className='text-input py-1 px-3 text-xl text-black mb-3 block w-full rounded-lg bg-white-off border-transparent    focus:border-gray-500 focus:bg-white focus:ring-0' id='location_id' placeholder='zip code...' />
        <input type="submit" value="Call Your Reps" className='font-btn disabled:opacity-80 inline-block h-14 text-2xl rounded-lg relative pt-3 p-5 uppercase text-black btn-color-alt font-bold w-full' />
      </form>
    </>
  ) : (
    <>
      <h2>{cta}</h2>
      <div className='text-xl mb-4 mt-4 cta' dangerouslySetInnerHTML={{ __html: formCopy }}></div>
      <div id='can-letter-area-ceasefire-now-8'></div>
    </>
  )
  // {takeAction}
  let showLegs = (
    <>
      <div className='header'>
        <span>
          <span className='title'>
            Name
          </span>
        </span>
        <span className='check'>
          <span className='title'>Stance</span>
        </span>
        <span className='check'>
          <span className='title'>
            Source
          </span>
        </span>
      </div>
      {legsToShow.sort((a, b) => {
        if (a['prefix'] !== 'Sen.' && b['prefix'] === 'Sen.') return 1
        if (b['prefix'] !== 'Sen.' && a['prefix'] === 'Sen.') return -1
        return 0
      }).map((leg, i) => {
        const party = getParty(leg)
        return (
          <div className={classNames('leg', party)} key={i}>
            <span> <span className='name'>{leg['display name']}</span> ({leg['district']}-{party.toUpperCase().slice(0, 1)})</span>
            <span className={classNames('check', { yes: legIsYes(leg), no: !legIsYes(leg), partial: legIsPartial(leg) })}>
            </span>
            <span className={classNames('check')}> 
              <a href={leg['source url']} target='_blank' rel='noopener noreferrer'>{leg['source']}</a>
            </span>
          </div>
        )
      })}
    </>
  )
  if (legsToShow.length === 0 && settings.filter) {
    showLegs = (
      <div className='justify-center px-4'>
        <a className='btn btn-primary text-center' onClick={submit}>Search Address "{settings.filter}"</a>
      </div>
    )
  }

  return (
    <div className={classNames('App', { submited: zipReps })}>
      <header>
        <video autoPlay loop muted >
          <source src={BgVideo} />
        </video>
        {t}
      </header>
      <div className={classNames('bottom', { continueReading })}>
        <div className='total-take-action take-action'>
          {isMobile && totalTakeAction}
        </div>
        <div className='left-side'>
          {copy && (
            <div className='headline' dangerouslySetInnerHTML={{ __html: copy }}></div>
          )}
        </div>
        <div className='leg-wrap'>
          <div className='content'>
            {summary && (
              <p className='summary'>
                {summary}
              </p>
            )}
            <WpContent id={682605} onLoad={res => {
              console.log(res)
                setTitle(res.title)
                setLogos(res.acf.logos || [])
                setCopy(res.acf.body_copy)
                setFormCopy(res.acf.form_copy)
              }}
            />
            {theFilters}
            <div className='legs'>
              {showLegs}
            </div>
          </div>
          <div className={classNames('take-action', { submited: zipReps })}>
            <div className='take-action-wrap'>
              {!isMobile && totalTakeAction}
              {partners}
            </div>
          </div>
        </div>
        {partners}
      </div>
    </div>
  )
}

export default App
