

// const SHEET_ID = '1_q-5oSSqKddnimhNFEiWr4epFP-BSGD0KS5-2eRYoZc' // yemen
// const SHEET_ID = '1jW2j-wVx46mujmGrB0z8IRZ-K-lcw9EgINGvCZOuGFc' // ceasefire
const SHEET_ID = '15JjZDBUzjbjagmFLhVNTOz3sTmngA_HCgxfy-HNKXvQ' // ceasefire
const API_KEY = 'AIzaSyBaq5a9lJpBUgnAXj2k6iZe0lw2czb8250'
export const getSheetv4 = (sheetRange, cb) => {
  return fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values:batchGet?key=${API_KEY}&ranges=${sheetRange}`)
    .then(response => response.json())
    .then(res => {
      const values = res.valueRanges[0].values
      const keys = values[0].map(k => k.toLowerCase())
      const ret = values.slice(1).map(row => {
        const obj = {}
        keys.forEach((key, i) => {
          obj[key] = row[i]
        })
        return obj
      })
      if (cb) {
        cb(ret)
      }
      return ret
    })
    .catch(err => {
      console.log(`google sheets v4 error: ${err}`)
    })
}
